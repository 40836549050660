
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,800&display=swap');

@font-face{
    font-family: 'SF-Pro-Display';
    src: url('./fonts/SF-Pro-Display-Thin.otf');
    font-weight: 200;
}

@font-face{
    font-family: 'SF-Pro-Display';
    src: url('./fonts/SF-Pro-Display-Light.otf');
    font-weight: 300;
}

@font-face{
    font-family: 'SF-Pro-Display';
    src: url('./fonts/SF-Pro-Display-Regular.otf');
    font-weight: 400;
}

@font-face{
    font-family: 'SF-Pro-Display';
    src: url('./fonts/SF-Pro-Display-RegularItalic.otf');
    font-weight: 400;
    font-style: italic;
}

@font-face{
    font-family: 'SF-Pro-Display';
    src: url('./fonts/SF-Pro-Display-Medium.otf');
    font-weight: 500;
}

@font-face{
    font-family: 'SF-Pro-Display';
    src: url('./fonts/SF-Pro-Display-Semibold.otf');
    font-weight: 600;
}

@font-face{
    font-family: 'SF-Pro-Display';
    src: url('./fonts/SF-Pro-Display-Bold.otf');
    font-weight: 700;
}

* {
    font-family: 'SF-Pro-Display', sans-serif;
  }

body{
    margin: 0px;
    background-color: hsl(0, 0%, 98%);
    @media (prefers-color-scheme: dark) {
        background-color: hsl(0, 0%, 0%);
    }
}



div{
    white-space: pre-line;
}


h1{
    font-weight: 700;
    font-size: 30pt;
    color: hsl(0, 0%, 20%);
    @media (prefers-color-scheme: dark) {
        color: hsl(0, 0%, 80%);
    }
    margin-top: 100px;
}


h2{
    font-family: 'SF-Pro-Display', sans-serif;
    font-weight: 700;
    font-size: 15pt;
    color:hsl(0, 0%, 20%);
    @media (prefers-color-scheme: dark) {
        color: hsl(0, 0%, 80%);
    }
    margin-top: 40px;
    margin-bottom: 0px;
}


h3{
    font-family: 'SF-Pro-Display', sans-serif;
    font-weight: 700;
    font-size: 13pt;
    color:hsl(0, 0%, 20%);
    @media (prefers-color-scheme: dark) {
        color: hsl(0, 0%, 80%);
    }
    margin-top: 20px;
    margin-bottom: 0px;
}

p{
    font-family: 'SF-Pro-Display', sans-serif;
    font-weight: 300;
    font-size: 15pt;
    color:hsl(0, 0%, 25%);
    @media (prefers-color-scheme: dark) {
        color: hsl(0, 0%, 75%);
    }
    text-align: justify;
    line-height: 150%;
}


a:link, a:visited {
    color: hsl(0, 0%, 25%);
    fill: hsl(0, 0%, 25%);
    @media (prefers-color-scheme: dark) {
        color: hsl(0, 0%, 75%);
        fill: hsl(0, 0%, 75%);
    }
    text-decoration: none;
  }

  
a:hover, a:active {
    opacity: 50%;
}

.socialMediaLink {
    margin: 10px;
}



.appName{
    font-family: 'Montserrat', sans-serif;
    font-weight: 800;
    font-size: 44pt;
    @media (prefers-color-scheme: light) {
        color: black;
    };
    @media (prefers-color-scheme: dark) {
        color: white;
    };
}

.appNameSmall{
    font-family: 'Montserrat', sans-serif;
    font-weight: 800;
    font-size: 18pt;
    @media (prefers-color-scheme: light) {
        color: black;
    };
    @media (prefers-color-scheme: dark) {
        color: white;
    };
}


.slogan{
    font-family: 'SF-Pro-Display', sans-serif;
    font-weight: 200;
    font-size: 18pt;
    color: black;
    @media (prefers-color-scheme: dark) {
        color: white;
    }
    
}

.intro{
    font-family: 'SF-Pro-Display', sans-serif;
    font-weight: 300;
    font-size: 18pt;
    text-align: center;
    color: hsl(0, 0%, 50%);
    width: 70%;
}


.featureTitle{
    font-weight: 700;
    font-size: 50pt;
    color: black;
    @media (prefers-color-scheme: dark) {
        color: white;
    }
}

.featureSubtitle{
    font-weight: 700;
    font-size: 30pt;
    color: hsl(0, 0%, 50%);
}

.featureDescription{
    font-weight: 300;
    font-size: 18pt;
    color: black;
    @media (prefers-color-scheme: dark) {
        color: white;
    }
    padding: 5%;
    box-sizing: border-box;
}

.featureQuote{
    font-weight: 200;
    font-size: 30pt;
    color: white;
    padding: 5%;
    box-sizing: border-box;
    text-align: center;
}

.featureQuoteAuthor{
    font-weight: 400;
    font-size: 20pt;
    font-style: italic;
    color: white;
    width: 100%;
    padding-left: 50%;
    padding-right: 15%;
    box-sizing: border-box;
    text-align: right;
}

.featureMedia{
    display: flex;
    align-items: center;
    justify-content: center;

}

.featureImage{
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    border-radius: 20px;
}

.width50{
    width: 50%;
}

.minorFeature{
    width: 250px;
    margin: 10px;
    padding: 0px 20px;
    background-color: white;
    @media (prefers-color-scheme: dark) {
        background-color: hsl(0, 0%, 5%);
    }
    border-radius: 12px;
    box-shadow: 0px 6px 6px hsla(0, 0%, 0%, 0.1);
}

.homepage {
    display: block;
    overflow: hidden;
}

.strip {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.stripContent{
    display: block;
    width: 90%;
    max-width: 980px;
}

.infoPage .stripContent{
    display: block;
    width: 90%;
    max-width: 800px;
}

.fullHeight{
    min-height: 100vh;
}

.logoStrip{
    background-color: white;
    @media (prefers-color-scheme: dark) {
        background-color: black;
    }
}

.footerStrip{
    background-color: hsl(0, 0%, 95%);
    border-top: 1px solid hsl(0, 0%, 85%);
    @media (prefers-color-scheme: dark) {
        background-color: hsl(0, 0%, 5%);
        border-top: 1px solid hsl(0, 0%, 15%);
    }
}

.headerBar {
    background-color:white;
    box-shadow: 0px 0px 6px hsla(0, 0%, 0%, 0.12);
    @media (prefers-color-scheme: dark) {
        background-color:black;
        box-shadow: 0px 0px 6px hsla(0, 0%, 0%, 0.12);
    }
    position: sticky;
    top: 0;
    width: 100%;
  }

.hStack{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.vStack{
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.vStack-left{
    align-items: flex-start;
}
.vStack-center{
    align-items: center;
}
.vStack-right{
    align-items: flex-end;
}

.vStack-top{
    justify-content: flex-start;
}

.hStack-left{
    justify-content: flex-start;
}
.hStack-center{
    justify-content: center;
}
.hStack-right{
    justify-content: flex-end;
}

.hStack-stretch{
    align-items: stretch;
}


.spacer{
    opacity: 0;
}

.spacer-xs{
    height: 15px;
    width: 15px;
}

.spacer-s{
    height: 25px;
    width: 25px;
}

.spacer-m{
    height: 50px;
    width: 50px;
}

.spacer-l{
    height: 100px;
}

.spacer-xl{
    height: 200px;
}


.separator{
    width: 100%;
    height: 1px;
    background-color: hsl(0, 0%, 90%);
    @media (prefers-color-scheme: dark) {
        background-color: hsl(0, 0%, 10%);
    }
}

.bg-1{
    background: linear-gradient(60deg, hsl(240,100%,25%) 0%, hsl(200,100%,50%) 50%, hsl(180,100%,75%) 100%)
}
